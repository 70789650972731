p {
    font-size: 13.5px;
}

.card {
    border: black;
    border-width: 5px;
}
.projectPic {
    transition: all .5s ease;
    width: 200px;
    height: 200;
    object-fit: cover;

}

.projectPic:hover {
    transform: scale(1.2);
}

a {
    font-size:13.5px;
}

a:hover {
    font-size:15px;
}