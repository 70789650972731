.list {
    list-style-type: none;
    padding: 0;
}

.link:link, .link:visited, .link:active, .link:focus {
    color: black;
    text-decoration: none;
    font-size: 24px;
}

.link:hover {
    color: grey;
    font-size: 28px;
    transition: all 0.4s ease;
    
}
